import React from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Header from "components/Header/Header.js"
import Button from "components/CustomButtons/Button.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import LinearProgress from '@material-ui/core/LinearProgress';

import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js"

import { useStaticQuery, graphql } from "gatsby"
import { gql, useQuery } from "@apollo/client"

const useStyles = makeStyles(navbarsStyle)

const SiteHeader = ({ title }) => {
  const APOLLO_QUERY = gql`
    {
      aboutSingleton {
        company_name
        logo{
          path
        }
      }
    }
  `

  const static_data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          cmsURL
          pages {
            title
            url
            anchors
          }
        }
      }
    }
  `)
  //   const data = useStaticQuery(graphql`

  //   fragment Pages on Site{site {
  //     siteMetadata{
  //     pages
  //       {
  //         title,
  //         url
  //       }
  //     }
  //   }
  // }
  // `)

  // console.log(data)

  

  const classes = useStyles()

  const { loading, error, data } = useQuery(APOLLO_QUERY)

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`
  if (!data.aboutSingleton) {data.aboutSingleton = {}}

  const menuItems = static_data.site.siteMetadata.pages.map(item => {
    let itemclass = classes.navLink
    if (item.url+"/"==location.pathname){
      itemclass = classes.navLink+" " + classes.navLinkActive
  }
  // console.log(item.anchors)
    // var has_anchor = false

    // if (item.anchors){
    //   has_anchor = true
    // }
    // // const has_anchor = (item.anchors == undefined) : 1 ? 0
    // console.log(has_anchor)

    let has_anchor = item.anchors == undefined ? false : false
    return(
    <ListItem className={classes.listItem} key={item.title}>

      {has_anchor
      ?
        <CustomDropdown
                      buttonText={item.title}
                      // dropdownHeader="Dropdown Header"
                      buttonProps={{
                        className: classes.navLink,
                        color: "transparent",
                        href: item.url
                      }}
                      dropdownList={[
                        item.anchors
                      ]}
                    />
      
      :


      <Button
        href={item.url}
        className={itemclass}
        // onClick={e => e.preventDefault()}
        color="transparent"
      >
        {item.title}
      </Button>


      }

    </ListItem>
  )})


  const contact_url = location.pathname=='/contact/'?1:0
  

  return (

    (
      contact_url?

    <Header
      // brand={data.aboutSingleton.company_name}
      brand={<img src={static_data.site.siteMetadata.cmsURL+data.aboutSingleton.logo.path} style={{width:"auto", height:"80px"}} alt="logo" />}
      color="dark"
      links={
        <List className={classes.list + " " + classes.mlAuto}>{menuItems}</List>
      }
      fixed
    />

    :

    <Header
      // brand={data.aboutSingleton.company_name}
      brand={<img src={static_data.site.siteMetadata.cmsURL+data.aboutSingleton.logo.path} style={{width:"auto", height:"80px"}} alt="logo" />}
      color="transparent"
      links={
        <List className={classes.list + " " + classes.mlAuto}>{menuItems}</List>
      }
      fixed
      changeColorOnScroll={{
        height: 80,
        color: "dark",
      }}
    />

    )

  )
}

export default SiteHeader
