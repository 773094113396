import React, { useState, useEffect } from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Footer from "components/Footer/Footer.js"
import LinearProgress from "@material-ui/core/LinearProgress"

import styles from "assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.js"

import { useStaticQuery, graphql } from "gatsby"
import { gql, useQuery } from "@apollo/client"

const useStyles = makeStyles(styles)



const SiteFooter = () => {

  const APOLLO_QUERY = gql`
  {
    aboutSingleton {
      company_name
      facebook
      instagram
      pinterest
    }
  }
`

  const static_data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            title
            url
          }
        }
      }
    }
  `)

  const { loading, error, data } = useQuery(APOLLO_QUERY)


  const classes = useStyles()

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`

  const menuItems = static_data.site.siteMetadata.pages.map(item => (
    <ListItem className={classes.inlineBlock} key={item.title}>
      <a href={item.url} className={classes.block}>
        {item.title}
      </a>
    </ListItem>
  ))


  return (
    <Footer
      theme="dark"
      content={
        <div>
          <div className={classes.left}>
              <a href="/" className={classes.footerBrand}>
                {data.aboutSingleton.company_name}
              </a>
          </div>
          <div className={classes.pullCenter}>
            <List className={classes.list}>{menuItems}</List>
          </div>
          <div className={classes.rightLinks}>
            <ul>
              {/* <li>
                <a href="https://twitter.com/CreativeTim">
                  <i
                    className={
                      "fab fa-twitter" +
                      " " +
                      classes.iconSocial +
                      " " +
                      classes.btnTwitter
                    }
                  />
                </a>
              </li> */}
              <li>
                <a href={data.aboutSingleton.facebook}>
                  <i
                    className={
                      "fab fa-facebook" +
                      " " +
                      classes.iconSocial +
                      " " +
                      classes.btnDribbble
                    }
                  />
                </a>
              </li>
              <li>
                <a href={data.aboutSingleton.instagram}>
                  <i
                    className={
                      "fab fa-instagram" +
                      " " +
                      classes.iconSocial +
                      " " +
                      classes.btnInstagram
                    }
                  />
                </a>
              </li>
              <li>
                <a href={data.aboutSingleton.pinterest}>
                  <i
                    className={
                      "fab fa-pinterest" +
                      " " +
                      classes.iconSocial +
                      " " +
                      classes.btnInstagram
                    }
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      }
    />
  )
}

export default SiteFooter
