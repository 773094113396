import React from "react"
import Layout from "components/Layout/Layout"

import SiteHeader from "components/SiteHeader"
import SiteFooter from "components/SiteFooter"
import { makeStyles } from "@material-ui/core/styles"
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js"

import { useStaticQuery, graphql } from "gatsby"

const useStyles = makeStyles(landingPageStyle)

const PageLayout = ({ children }) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      cmsAPI {
        aboutSingleton {
          company_name
        }
      }
    }
  `)

  return (
    <Layout>
      <SiteHeader title={data.cmsAPI.aboutSingleton.company_name} />
      {children}
      <SiteFooter />
    </Layout>
  )
}

export default PageLayout
